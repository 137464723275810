import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";

import theme from "../theme/theme";
import { type } from "../theme/typography";
import Color from "color";

import Layout from "../components/layout";
import P5component from "../components/p5js/p5component";
import molnar from "../components/p5js/molnar";
import Container from "../components/container";
import SEO from "../components/seo";

const colors = {
  backgroundColor: "hsl(231, 29%, 17%)",
  navColor: "hsl(193, 44%, 90%)",
  bodyColor: "hsl(231, 8%, 62%)",
  footerColor: "hsla(231, 8%, 62%, 0.8)"
};

const SketchWrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
`;

const P5Overlay = styled.div`
  position: relative;
  z-index: 2;
  pointer-events: none;
`;

const MainHeadline = styled.h1`
  color: hsl(10, 100%, 70%);
  max-width: 600px;
  margin-top: 160px;
  ${type("header1")};
  ${theme.breakpoint.down("medium")`
    max-width: 600px;
    margin-top: 140px;
  `}
  ${theme.breakpoint.down("small")`
    margin-top: 100px;
  `}
  ${theme.breakpoint.down("small")`
    ${type("header1mobile")};
  `}
`;

const RecentContentContainer = styled.div`
  display: flex;
  margin: ${theme.spacing.xxlarge} -${theme.spacing.small};

  ${theme.breakpoint.down("medium")`
    display: block;
    margin: ${theme.spacing.xlarge} -${theme.spacing.small};
  `}
`;

const RecentContent = styled.div`
  pointer-events: auto;
  max-width: 560px;
  flex: 1 1 0;
  padding: 0 ${theme.spacing.small};
  ${type("bodySmall")};
  ${theme.breakpoint.down("medium")`
    &:nth-child(2) {
      margin-top: ${theme.spacing.large};
    }
  `}
  ${theme.breakpoint.down("small")`
    width: 100%;
  `}
`;

const RecentContentHeadline = styled.h3`
  ${type("nav")};
  color: ${colors.bodyColor};
  margin-bottom: ${theme.spacing.xsmall};
`;

const RecentContentLinks = styled.div`
  margin-bottom: ${theme.spacing.small};
  a {
    margin-bottom: 4px;
    text-transform: capitalize;
    display: inline-block;
    transition: color ease-in-out 150ms;
    color: ${Color(colors.bodyColor)
      .lighten(0.6)
      .string()};
    border-color: ${Color(colors.bodyColor)
      .fade(0.6)
      .string()};
    &:hover {
      background-color: ${Color(colors.bodyColor)
        .fade(0.8)
        .string()};
    }
  }
  p {
    color: ${colors.bodyColor};
  }
`;

export default ({ data }) => (
  <React.Fragment>
    <SEO title="" />

    <Layout
      backgroundColor={colors.backgroundColor}
      bodyColor={colors.bodyColor}
      footerColor={colors.footerColor}
      navColor={colors.navColor}
    >
      <SketchWrapper>
        <P5component sketch={molnar} />
      </SketchWrapper>
      <Container>
        <P5Overlay>
          <MainHeadline style={{ pointerEvents: "none" }}>
            I'm a product leader, designer, and founder in California.
          </MainHeadline>

          <RecentContentContainer>
            <RecentContent>
              <RecentContentHeadline>Recent Work</RecentContentHeadline>
              {data.allMarkdownRemark.edges
                .filter(({ node }) => node.frontmatter.template === "work")
                .map(({ node }, index) => (
                  <React.Fragment key={node.id}>
                    {index <= 2 ? (
                      <RecentContentLinks>
                        <Link to={node.fields.slug}>
                          {node.frontmatter.title}
                        </Link>
                        <p>{node.frontmatter.introShort}</p>
                      </RecentContentLinks>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                ))}
            </RecentContent>
            <RecentContent>
              <RecentContentHeadline>Recent Writing</RecentContentHeadline>
              {data.allMarkdownRemark.edges
                .filter(({ node }) => node.frontmatter.template === "writing")
                .map(({ node }, index) => (
                  <React.Fragment key={node.id}>
                    {index <= 2 ? (
                      <RecentContentLinks>
                        <Link to={node.fields.slug}>
                          {node.frontmatter.title}
                        </Link>
                        <p>{node.frontmatter.subtitle}</p>
                      </RecentContentLinks>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                ))}
            </RecentContent>
          </RecentContentContainer>
        </P5Overlay>
      </Container>
    </Layout>
  </React.Fragment>
);

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date, frontmatter___order], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            template
            introShort
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
