// Inspired by Vera Molnar's Interruptions series
// Based on https://www.openprocessing.org/sketch/500848

export default p => {
  const row = 30;
  let lines = [];
  let lastWindowWidth = window.innerWidth;

  const fixedCellWidth = 18;

  p.setup = function() {
    p.createCanvas(p.windowWidth, 576);
    lines = calcLines();
    p.colorMode(p.HSL);
    p.noLoop();
  };

  p.myCustomRedrawAccordingToNewPropsHandler = function(props, windowWidth) {
    if (windowWidth && lastWindowWidth !== windowWidth) {
      resetSketch(windowWidth);
    }
  };

  const resetSketch = size => {
    p.resizeCanvas(size, 576);
    lines = calcLines();
    p.redraw();
    lastWindowWidth = size;
  };

  // Grab values from array and draw the lines
  const drawLines = () => {
    for (let i = 0; i < lines.length; i++) {
      if (lines[i][4] !== 0) {
        if (lines[i][1] < 140) {
          p.stroke(190, 20, 38, (1 / 180) * (lines[i][1]) + 0.1);
        } else {
          p.stroke(190, 20, 38, (1 / p.height) * (p.height - lines[i][1]));
        }
        p.line(lines[i][0], lines[i][1], lines[i][2], lines[i][3]);
      }
    }
  };

  const calcLines = () => {
    // Initialize an array to hold all of our x1, y1, x2, y2 coordinates
    let a = [];

    // To determine the grid cell width, divide by the number of columns
    const cellSize = fixedCellWidth;

    // Calculate the center of the cell
    let xCenter = cellSize / 2;
    let yCenter = cellSize / 2;

    // For each point in grid (columns x rows)
    for (let x = 0; x <= (p.width / cellSize) * 1.5; x++) {
      for (let y = 0; y <= row; y++) {
        // Set delta between x1 and x2, draw out from center
        let xDelta = p.random(cellSize + 5) - cellSize / 2;
        let x1 = xCenter - xDelta;
        let x2 = xCenter + xDelta;

        // Set delta between y1 and y2, draw out from center
        // No idea about the math here
        let yDelta = Math.pow(
          Math.pow(cellSize / 1.5, 2) - Math.pow(xDelta, 2),
          0.5
        );
        let y1 = yCenter - yDelta;
        let y2 = yCenter + yDelta;

        let hole;

        // hole = Math.floor(p.random(row / y) * 0.8);

        // if not the first item in array and the previous line wasn't a hole, give it a good shot to be a hole
        if (a[a.length - 1] !== undefined && a[a.length - 1][4] === 0) {
          hole = Math.floor(p.random(2));
          // if not the first row and this spot in previous row wasn't a hole, give it a good shot at being a hole
        } else if (
          a[a.length - row] !== undefined &&
          a[a.length - row][4] === 0
        ) {
          hole = Math.floor(p.random(2));
          // otherwise, unlikely to be a hole
        } else {
          hole = Math.floor(p.random(100));
        }

        // Push the point into the array
        a.push([x1, y1, x2, y2, hole]);

        // Shift down 1 cell on the y axis
        yCenter += cellSize;
      }

      // Shift over 1 cell on the x axis, back up to 1st cell on y axis
      xCenter += cellSize / 1.5;
      yCenter = cellSize / 2;
    }
    return a;
  };

  p.draw = function() {
    p.background(230, 28, 17);
    drawLines();
  };

  p.windowResized = function() {
    // scrolling on iOS triggers a window.resize event for some reason
    // check that window has actually resized before proceeding
    if (p.windowWidth !== lastWindowWidth) {
      resetSketch(p.windowWidth);
    }

    // Notes:
    // Chrome on iOS does not register new width on orientation change until scroll happens
    // Unknown what Android / Firefox do
  };
};
